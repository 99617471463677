export const environment = {
  production: false,
  redirectUrl: 'https://dasaua.testapps.alcoa.com/',
  clientId: '39a9d9be-38a2-4c94-9d26-ce352c7ce03d',
  tenantId: '37a72eb7-1faf-49d3-9ddc-6b923b751bc0',
  authority: 'https://login.microsoftonline.com/37a72eb7-1faf-49d3-9ddc-6b923b751bc0/',
  WEBAPIURL: 'https://devapi.alcoa.com/Alcoa.DAS.TEST/v1',
  consentScopes: ["user.read"],
  scope: ["api://9546d955-4ebe-4257-9d83-bebe9d5de681.alcoa.com/user_impersonation"],
  AssetMaintenancePostSubscriptionKey: "c08821ae1d344e559c56daddc4c68a21",
  FileUploadSize:20971520
};
